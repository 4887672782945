import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
  MainHeader,
  WhoWeAre,
  Services,
  WhyChooseUs,
} from "../components/sections"
import SEO from "../components/seo"
import { BlackSection } from "../components/structure/BlackSection"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta
        name="facebook-domain-verification"
        content="9ohwh9vu6xu6on9gs5gbd9273lyhp2"
      />
    </Helmet>
    <SEO title="Home" />
    <MainHeader />
    <WhoWeAre />
    <BlackSection title="Book a free IT solutions quote today" />
    <Services />
    <BlackSection title="Invest in IT, Invest in your business" />
    <WhyChooseUs />
    <BlackSection title="How we can help your business?" />
  </Layout>
)

export default IndexPage
